import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';

Vue.use(Vuex);

var base = 'https://www.dannybastiaanse.com/';

export default new Vuex.Store({
	state: {
		base: base,
		languages: [],
		pages: [],
		shopProducts: [],
		structure: [],
		translations: [],
	},


	getters: {
		allCategories: state => {
			let cats = [];

			for (let i = 0; i < state.pages.length; i++)
			{
				let c = state.pages[i];

				if (c.template == 3) cats.push(c);
			}

			return cats;
		},


		base: state => {
			return state.base;
		},


		languages: state => {
			return state.languages;
		},


		pages: state => {
			return state.pages;
		},


		products: state => {
			let products = [];

			for (let i = 0; i < state.pages.length; i++)
			{
				let p = state.pages[i];

				if (p.template == 6) products.push(p);
			}

			return products;
		},


		shopProducts: state => {
			return state.shopProducts;
		},


		structure: state => {
			return state.structure;
		},


		translations: state => {
			return state.translations;
		},
	},


	mutations: {
		setLanguages (state, value)
		{
			state.languages = value;
		},


		setPages (state, value)
		{
			state.pages = value;
		},


		setShopProducts (state, value)
		{
			state.shopProducts = value;
		},


		setStructure (state, value)
		{
			state.structure = value;
		},


		setTranslations (state, value)
		{
			state.translations = value;
		},
	},


	actions: {
		getLanguages: (context, payload) => {
			return axios.get(base + 'api/languages/', { params: payload })
			.then(response => {
				context.commit('setLanguages', response.data);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},


		getPages: (context, payload) => {
			return axios.get(base + 'api/pages/', { params: payload })
			.then(response => {
				context.commit('setPages', response.data);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},


		getShopProducts: (context, payload) => {
			return axios.get(base + 'api/products/', { params: payload })
			.then(response => {
				context.commit('setShopProducts', response.data);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},


		getStructure: (context, payload) => {
			return axios.get(base + 'api/structure/', { params: payload })
			.then(response => {
				context.commit('setStructure', response.data);
			});
		},


		getTranslations: (context, payload) => {
			return axios.get(base + 'api/translations/', { params: payload })
			.then(response => {
				context.commit('setTranslations', response.data);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},
	},
});
