import { mapGetters } from 'vuex';

var vm;

export const pageMixin = {
	data()
	{
		return {
			categories: [],
		};
	},


	computed: {
		...mapGetters([
			'allCategories',
			'pages',
		]),


		language()
		{
			return vm.$route.params.lang;
		},


		page()
		{
			// Get the current path
			let path = vm.$route.path.length > 1 ? vm.$route.path + '/' : vm.$route.path;

			if (['image', 'product'].indexOf(vm.$route.name) >= 0)
			{
				path =	path.split('/')
						.slice(0, -2)
						.join('/') + '/';
			}

			if (path.substr(-2) == '//') path = path.substr(0, path.length - 1);

			// Loop through the existing pages
			for (let i = 0; i < vm.pages.length; i++)
			{
				let p = vm.pages[i],
					pPath = '/' + p.path.replace(p.http_host, '');

				if (pPath == path) return p;
			}


			return {};
		},


		pageLoaded()
		{
			return Object.keys(vm.page).length > 0;
		},


		title()
		{
			return vm.page.longtitle.length > 0 ? vm.page.longtitle : vm.page.pagetitle;
		},


		vars()
		{
			return vm.page.variables;
		},
	},


	methods: {
		getCategories()
		{
			let cats = [];

			for (let i = 0; i < vm.allCategories.length; i++)
			{
				let c = vm.allCategories[i];

				if (c.language == vm.language) cats.push(c);
			}

			vm.categories = cats;
		},
	},


	created()
	{
		vm = this;

		vm.getCategories();
	},
}
