<template>
	<router-link :to="{ name: 'category', params: { alias: page.alias } }" class="preview">
		<figure :style="background">
			<figcaption>
				{{ page.pagetitle }}
			</figcaption>
		</figure>
	</router-link>
</template>

<script>
var vm;

export default {
	props: {
		breakpoint: [String,  Number],
		page: Object,
	},


	computed: {
		background()
		{
			let img = vm.page.variables.bannerImage[vm.breakpoint];

			return "background-image: url('" + img + "');";
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
