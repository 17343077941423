<template>
	<main v-if="pageLoaded" class="home">
		<section class="hero" :style="background">
			<div class="content">
				<div v-html="page.content" />

				<div class="toolbar">
					<cta v-if="hasCta1" :cta="cta1"></cta>
					<cta v-if="hasCta2" :cta="cta2"></cta>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { pageMixin } from '@/mixins/page';

import CallToAction from '@/components/CallToAction.vue';

var vm;

export default {
	mixins: [
		pageMixin,
	],


	components: {
		Cta: CallToAction,
	},


	props: {
		breakpoint: [String, Number],
	},


	computed: {
		background()
		{
			let img = vm.page.variables.bannerImage[vm.breakpoint];

			return "background-image: url('" + img + "');";
		},


		cta1()
		{
			let cta = false,
				url = vm.page.variables.ctaUrl;

			if (typeof(url) != 'undefined')
			{
				for (let i = 0; i < vm.pages.length; i++)
				{
					let p = vm.pages[i];

					if (p.id == url)
					{
						let pPath = p.path.replace(vm.page.http_host, '/');
						pPath = pPath.substr(0, (pPath.length - 1));

						cta = {
							color: vm.page.variables.ctaColor,
							path: pPath,
							text: vm.page.variables.ctaText,
						};
					}
				}
			}

			return cta;
		},


		cta2()
		{
			let cta = false,
				url = vm.page.variables.ctaUrl2;

			if (typeof(url) != 'undefined')
			{
				for (let i = 0; i < vm.pages.length; i++)
				{
					let p = vm.pages[i];

					if (p.id == url)
					{
						let pPath = p.path.replace(vm.page.http_host, '/');
						pPath = p.class_key == 'modWebLink' ? p.content : pPath.substr(0, (pPath.length - 1));

						cta = {
							color: vm.page.variables.ctaColor2,
							isExternal: p.class_key == 'modWebLink',
							path: pPath,
							text: vm.page.variables.ctaText2,
						};
					}
				}
			}


			return cta;
		},


		hasCta1()
		{
			return vm.cta1 !== false;
		},


		hasCta2()
		{
			return vm.cta2 !== false;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
