<template>
	<transition-group name="fade" class="body-wrap">

		<article v-if="loaded" :class="articleClasses" key="article">

			<app-header @setLocked="lockScroll" :page="page" :cartSize="cartSize" />

			<transition :name="transition" mode="out-in">
				<router-view :breakpoint="breakpoint" v-if="page !== {}" :key="viewKey" :class="$route.name" @cartUpdate="getCartSize" :addedItems="addedItems" :cartSize="cartSize" />
			</transition>

			<app-footer />

		</article>


		<loader key="loader" v-else />

	</transition-group>
</template>

<script>
// require('vue-image-lightbox/dist/vue-image-lightbox.min.css');

import { mapActions, mapGetters } from 'vuex';

import AppFooter from '@/components/Footer.vue';
import AppHeader from '@/components/Header.vue';
import Loader from '@/components/Loader.vue';

import { pageMixin } from '@/mixins/page';

var vm;

export default {
	mixins: [
		pageMixin,
	],


	components: {
		AppFooter,
		AppHeader,
		Loader,
	},


	data()
	{
		return {
			addedItems: 0,
			breakpoint: 'full',
			breakpoints: [
				1920,
				1280,
				800,
				640,
			],
			cartSize: 0,
			lang: null,
			locked: false,
			showNav: false,
			transition: 'fade',
		};
	},


	computed: {
		...mapGetters([
			'languages',
			'pages',
			'shopProducts',
			'translations',
		]),


		articleClasses()
		{
			let cl = [];

			if (vm.locked) cl.push('locked');

			return cl.join(' ');
		},


		loaded()
		{
			let l = true;

			if (vm.pages.length === 0) l = false;
			if (vm.shopProducts.length === 0) l = false;
			if (vm.translations.length === 0) l = false;

			return l;
		},


		metaData()
		{
			// If no page is loaded yet, return false
			if (Object.keys(vm.page).length === 0) return false;

			let title = vm.page.longtitle.length > 0 ? vm.page.longtitle : vm.page.pagetitle;

			let img = Object.prototype.hasOwnProperty.call(vm.page.variables, 'image') ? vm.page.variables.image : 'img/favicon/android-chrome-512x512.png';

			let meta = {
				description: vm.page.description,
				keywords: vm.page.keywords,
				title: title,
				image: img,
			}

			return meta;
		},


		viewKey()
		{
			let path = vm.$route.path;

			if (vm.$route.name == 'product')
			{
				path = path.split('/').slice(0, -1).join('/');
			}

			return path;
		},
	},


	metaInfo ()
	{
		return {
			title: vm.metaData.title,
			titleTemplate: '%s | Danny Bastiaanse Photography',
			htmlAttrs: {
				lang: 'nl',
				// amp: true,
			},
			meta: [
				{
					charset: 'utf-8'
				},
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1'
				},
				{
					name: 'description',
					content: vm.metaData.description,
				},
				{
					name: 'keywords',
					content: vm.metaData.keywords,
				},
				{
					name: 'og:image',
					content: vm.metaData.image,
				},
			]
		};
	},


	methods: {
		...mapActions([
			'getLanguages',
			'getPages',
			'getShopProducts',
			'getStructure',
			'getTranslations',
		]),


		getBreakpoint()
		{
			let w = document.documentElement.clientWidth,
				breakpoint = false;
			console.log('w: ' + w);

			for (let i = 0; i < vm.breakpoints.length; i++)
			{
				let bp = vm.breakpoints[i];

				if (bp >= w)
				{
					breakpoint = bp;
				}
			}

			vm.breakpoint = breakpoint === false ? 'full' : breakpoint;
		},


		getCartSize()
		{
			if (!vm.$session.has('cart')) return 0;

			let cart = JSON.parse(vm.$session.get('cart')),
				cs = 0;

			for (let i = 0; i < cart.length; i++)
			{
				cs += parseInt(cart[i].quantity);
			}

			vm.cartSize = cs;
		},


		lockScroll(val)
		{
			vm.showNav = val;
			vm.locked = val;
		},
	},


	created()
	{
		vm = this;

		vm.getBreakpoint();

		vm.$nextTick(() => {
			window.addEventListener('resize', vm.getBreakpoint);
		});


		vm.getLanguages()
		.then(() => {
			let lang = null;

			if (vm.$route.path == '/')
			{
				lang = vm.languages[0].lang;
			}
			else
			{
				lang = vm.$route.params.lang;
			}

			vm.lang = lang;
		});

		vm.getCartSize();
	},


	watch: {
		$route(to, from)
		{
			vm.showNav = false;

			vm.lang = to.params.lang;

			vm.transition = (to.name == 'category' && from.name == 'product') || (to.name == 'product' && from.name == 'category') ? '' : 'fade';
		},


		cartSize(to, from)
		{
			vm.addedItems = to - from;
		},


		lang(to)
		{
			vm.getStructure({
				key: to,
			})
			.then(() => {
				vm.getPages();
				vm.getShopProducts();
				vm.getTranslations();
			});
		},
	}
}
</script>

<style lang="scss">

	@import "assets/css/all.min.css";
	@import "assets/scss/app.scss";

</style>
