<template>
	<router-link v-if="link" :to="{ name: 'product', params: { alias: $route.params.alias, id: index } }" :class="classes">
		<figure :style="background" :class="page.variables.imgRatio" />
	</router-link>

	<div v-else :class="classes" @click="show">
		<figure :style="background" :class="page.variables.imgRatio" />
	</div>
</template>

<script>
var vm;

export default {
	props: {
		breakpoint: [String,  Number],
		index: Number,
		link: {
			type: Boolean,
			default: true,
		},
		products: Array,
	},


	computed: {
		background()
		{
			let img = vm.page.variables.image[vm.breakpoint];

			return "background-image: url('" + img + "');";
		},


		classes()
		{
			let cl = [
				'product',
				'preview',
			];

			cl.push(vm.getProductClasses(vm.index));


			if (vm.breakpoint >= 768 || vm.breakpoint == 'full')
			{
				cl.push(vm.getProductClasses(vm.index + 1, 'next-'));
			}

			if (vm.breakpoint >= 1024 || vm.breakpoint == 'full')
			{
				cl.push(vm.getProductClasses(vm.index + 2, 'next-next-'));
			}


			if (!vm.link)
			{
				cl.push('clickable');
			}

			return cl.join(' ');
		},


		page()
		{
			return vm.products[vm.index];
		},
	},


	methods: {
		getProductClasses(id, prefix)
		{
			if (id >= vm.products.length) return '';

			let cl = [],
				p = vm.products[id];

			if (typeof(prefix) === 'undefined') prefix = '';

			cl.push(prefix + p.variables.imgRatio);

			return cl.join(' ');
		},


		show({ target })
		{
			while (target.className.indexOf('preview') < 0)
			{
				target = target.parentNode;
			}

			vm.$emit('show', parseInt(target.dataset.index));
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
