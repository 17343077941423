import Vue from 'vue';
import VueGtag from "vue-gtag";
import VueLazyLoad from 'vue-lazyload';
import VueMeta from 'vue-meta';
import VueScrollReveal from 'vue-scroll-reveal';
import VueSession from 'vue-session';

import router from './router';
import store from './store';

import App from './App.vue';

import './registerServiceWorker';

Vue.use(VueGtag, {
	config: {
		id: "UA-173459685-1",
		anonymize_ip: true,
	},
}, router);

Vue.use(VueLazyLoad);

Vue.use(VueScrollReveal, {
	delay: 260,
	distance: '2rem',
	origin: 'bottom',
});

Vue.use(VueMeta, {
	// optional pluginOptions
	refreshOnceOnNavigation: true
});

Vue.use(VueSession);

Vue.config.productionTip = false


new Vue({
    router,
    store,
    render: h => h(App),
	mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
