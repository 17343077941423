<template>
	<main v-if="pageLoaded">
		<section class="intro">
			<div>
				<h1 v-html="title" />

				<div v-html="page.content" v-if="page.content.length > 0" />
			</div>
		</section>


		<section v-if="catProducts.length > 0" class="products gallery" :key="['gallery', breakpoint, language, lbStart].join('-')">
			<!--
			<ProductPreview
				v-for="(p, id) in catProducts"
				:key="[$route.name, id].join('-')"
				:breakpoint="breakpoint"
				:data-index="id"
				:index="id"
				:link="false"
				:products="catProducts"
				@show="show"
			/>
			-->

			<ProductPreview
				v-for="(p, id) in catProducts"
				:key="[$route.name, id].join('-')"
				:breakpoint="breakpoint"
				:data-index="id"
				:index="id"
				:link="true"
				:products="catProducts"
			/>

			
		</section>

		<transition name="fade" mode="out-in">
			<div class="modal" v-if="showModal" @click.self="closeModal">
				<div class="modal-body">
					<div class="product-info">
						<ProductPreview
							:breakpoint="breakpoint"
							:data-index="$route.params.id"
							:index="parseInt($route.params.id)"
							:products="catProducts"
							:link="false"
						/>

						<div class="product-meta" v-if="product !== false">
							<h4>
								{{ product.longtitle }}
							</h4>

							<div v-html="product.content" v-if="product.content.length > 0" />
							
							<div v-for="key in Object.keys(photoOptions)" :key="key" class="order-form">
							
								<h4>
									{{ getTranslation('type_' + key) }}
								</h4>

								<select class="variant" @input="setVariants">
									<option value="">
										{{ emptyOption }}
									</option>

									<option v-for="(po, id) in photoOptions[key]" :key="['po', id].join('-')" :value="po.id">
										{{ po.pagetitle }} ({{ po.variables.price }})
									</option>
								</select>
							
							</div>

							<div class="order-form order-cart">
								<h4>
									{{ getTranslation('quantity') }}
								</h4>

								<input type="number" v-model="quantity" min="1" />

								<transition name="fade" mode="out-in">
									<div v-if="feedback.length > 0" :class="feedbackClasses" :key="cartSize" @click="feedback = ''">
										{{ feedback }}
									</div>
								</transition>

								<button @click.prevent="addToCart" :class="processing ? 'processing' : 'success'" :disabled="disabled">
									<i v-if="processing" class="fas fa-spinner fa-spin"></i>
									<i v-else class="far fa-cart-plus"></i>

									<span>
										{{ getTranslation('btn_add_to_cart') }}
									</span>
								</button>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</transition>

		<!--
		<transition name="fade" mode="out-in">
			<LightBox v-if="showLb" :media="media" :startAt="lbStart" :showCaption="true" @onClosed="showLb = false" />
		</transition>
		-->

	</main>
</template>

<script>
import { mapGetters } from 'vuex';

import { pageMixin } from '@/mixins/page';

// import LightBox from 'vue-image-lightbox';
import ProductPreview from '@/components/ProductPreview.vue';

var vm;

export default {
	mixins: [
		pageMixin,
	],


	components: {
		// LightBox,
		ProductPreview,
	},


	props: {
		addedItems: Number,
		breakpoint: [String, Number],
		cartSize: Number,
	},


	data()
	{
		return {
			catProducts: [],
			feedback: '',
			lbStart: 0,
			quantity: 1,
			processing: false,
			showModal: this.$route.meta.showModal,
			variants: [],
		};
	},


	computed: {
		...mapGetters([
			'products',
			'shopProducts',
			'translations',
		]),


		cartItem()
		{
			let ci = {
				id: vm.product.id,
				quantity: vm.quantity,
				variants: vm.variants,
			};
			
			return ci;
		},


		disabled()
		{
			if (vm.variants.length == 0) return true;
			if (vm.quantity <= 0) return true;
			
			return false;
		},


		emptyOption()
		{
			return vm.getTranslation('option_none');
		},


		feedbackClasses()
		{
			let cl = ['feedback'];

			if (vm.addedItems > 0)
			{
				cl.push('success');
			}

			return cl.join(' ');
		},


		photoOptions()
		{
			let options = {},
				po = vm.product.variables.photoOptions.split('||');
			
			for (let i = 0; i < po.length; i++)
			{
				let p = vm.getProductOption(po[i]);

				// If not false, add the option
				if (p !== false)
				{
					if (!Object.prototype.hasOwnProperty.call(options, p.variables.productType))
					{
						options[p.variables.productType] = [p];
					}
					else
					{
						options[p.variables.productType].push(p);
					}
				}
			}

			let ordered = {};
				
			Object.keys(options)
			.sort()
			.forEach((key) => {
				ordered[key] = options[key];
			});
			
			return ordered;
		},


		product()
		{
			if (vm.$route.name != 'product') return false;

			let photo = vm.catProducts[vm.$route.params.id];
			
			for (let i = 0; i < vm.shopProducts.length; i++)
			{
				let sp = vm.shopProducts[i];

				if (sp.variables.product == photo.id && sp.language == 'shop' + vm.language)
				{
					return sp;
				}
			}

			return false;
		},


		media()
		{
			let m = [];

			for (let i = 0; i < vm.catProducts.length; i++)
			{
				let p = vm.catProducts[i],
					img = p.variables.image;

				m.push({
					thumb: img.thumbnail,
					src: img[vm.breakpoint],
					caption: p.longtitle.length > 0 ? p.longtitle : p.pagetitle,
					// srcset: '...' // Optional for displaying responsive images
				});
			}

			return m;
		},
	},


	methods: {
		addToCart()
		{
			// Double check that the button wasn't disabled
			if (!vm.disabled)
			{
				vm.processing = true;

				// Get the current cart
				let cart = vm.$session.has('cart') ? JSON.parse(vm.$session.get('cart')) : [];

				// Loop through all the variants to add
				for (let i = 0; i < vm.variants.length; i++)
				{
					let v = vm.variants[i];
					let isNew = true;

					// Loop through the cart items
					for (let j = 0; j < cart.length; j++)
					{
						let ci = cart[j];

						// If the item exists, increase the quantity
						if (ci.id == vm.product.id && ci.variant == v)
						{
							// Modify the quantity
							cart[j].quantity += parseInt(vm.quantity);

							// Acknowledge this isn't a new item
							isNew = false;
						}
					}

					// If the item is new, add it
					if (isNew)
					{
						cart.push({
							id: parseInt(vm.product.id),
							quantity: parseInt(vm.quantity),
							variant: v,
						});
					}
				}

				// Save the updated cart
				vm.$session.set('cart', JSON.stringify(cart));
				
				// Let the header know the cart size is updated
				vm.$emit('cartUpdate', Date.now());

				setTimeout(() => {
					vm.feedback = [vm.addedItems, vm.getTranslation('items_added_to_cart')].join(' ');
					vm.processing = false;
				}, 1300);
			}
		},


		closeModal()
		{
			vm.$router.push({ name: 'category', params: { lang: vm.$route.params.lang, alias: vm.$route.params.alias }});
		},


		getCatProducts()
		{
			let cp = [],
				tmp = {};

			for (let i = 0; i < vm.products.length; i++)
			{
				// let p = vm.products[i],
				// 	categories = p.variables.categories.split('||');
				let p = vm.products[i];

				if (Object.prototype.hasOwnProperty.call(p.variables, 'categories'))
				{
					let categories = p.variables.categories.split('||');

					if (categories.indexOf(vm.page.id) >= 0)
					{
						let key = p.menuindex;

						if (key.toString().length === 1) key = '00' + key;
						else if (key.toString().length === 2) key = '0' + key;

						key += '_' + p.id;

						tmp[key] = p;
					}
				}
			}

			let keys = Object.keys(tmp).sort();

			for (let i = 0; i < keys.length; i++)
			{
				let c = tmp[keys[i]];

				if (vm.$route.name == 'image' && c.id == vm.$route.params.id)
				{
					vm.lbStart = cp.length;
				}

				cp.push(c);
			}

			vm.catProducts = cp;
		},


		getProductOption(id)
		{
			// Loop through all pages
			for (let i = 0; i < vm.pages.length; i++)
			{
				let p = vm.pages[i];
				
				// If the ID matches, return the page
				if (p.id == id)
				{
					return p;
				}
			}

			// If no match was found, return false
			return false;
		},


		getTranslation (tag)
		{
			let lang = vm.$route.params.lang,
				tr = vm.translations;


			if (!Object.prototype.hasOwnProperty.call(tr, tag))
			{
				return false;
			}
			else
			{
				let t = tr[tag];

				return Object.prototype.hasOwnProperty.call(t, lang) ? t[lang] : t.en;
			}
		},


		setVariants()
		{
			let variants = [];

			document.querySelectorAll('.variant').forEach(el => {
				if (el.value.length > 0)
				{
					variants.push(el.value);
				}
			});

			vm.variants = variants;
		},
		
		
		show(id)
		{
			// vm.lbStart = id;
			// vm.showLb = true;
			
			vm.$router.push({ name: 'product', params: { lang: vm.$route.params.lang, alias: vm.$route.params.alias, id: id }});
		},
	},


	created()
	{
		vm = this;

		vm.getCatProducts();
		// vm.$session.remove('cart');
	},


	watch: {
		$route(to)
		{
			vm.showModal = to.name == 'product' || to.name == 'category' ? to.meta.showModal : false;
			vm.feedback = '';

			vm.setVariants();
		},
	}
}
</script>
