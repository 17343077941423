<template>
	<header :class="showNav ? 'open' : ''">

		<nav class="logo">
			<router-link :to="{ name: 'home' }" exact class="logo">
				<img src="img/logo.svg" alt="Danny Bastiaanse Photography" />
			</router-link>
		</nav>

		<!-- <span class="toggle" @click="lockScroll">
			<span></span>
			<span></span>
			<span></span>
		</span> -->

		<transition name="fade" mode="out-in">
			<nav class="nav" :key="'nav-' + $route.params.lang">
				<router-link :to="{ name: 'home' }" exact>
					<i class="fad fa-home"></i>

					<span>
						Home
					</span>
				</router-link>

				<nav-item v-for="item in nav" :item="item" :to="to(item)" :key="[item.alias, item.id].join('-')">
					<i :class="item.icon"></i>

					<span>
						{{ item.name }}
					</span>
				</nav-item>
			</nav>
		</transition>

		<nav class="social">
			<span>
				<a href="mailto:info@dannybastiaanse.com">
					<i class="fal fa-at"></i>
				</a>

				<a href="https://www.instagram.com/dannybastiaansephoto" target="_blank">
					<i class="fab fa-instagram"></i>
				</a>

				<a href="https://www.facebook.com/dannybastiaansephotography" target="_blank">
					<i class="fab fa-facebook-f"></i>
				</a>
			</span>

			<span>
				<nav-item :item="toCartItem" :to="{}" class="cart">
					<label>
						{{ cartSize }}
					</label>

					<i class="far fa-shopping-cart"></i>
				</nav-item>
			</span>

			<small v-if="languages.length > 0">
				<router-link v-for="lang in languages" :to="langSwitch(lang.lang)" :key="'lang-' + lang.lang">
					{{ lang.lang }}
				</router-link>
			</small>
		</nav>

	</header>
</template>

<script>
import { mapGetters } from 'vuex';

import NavItem from '@/components/NavItem.vue';

var vm;

export default {
	props: {
		cartSize: Number,
		page: Object,
		showNav: Boolean,
	},


	components: {
		NavItem,
	},


	data()
	{
		return {
			toCartItem: {
				external: true, href: '/cart'
			},
		};
	},


	computed: {
		...mapGetters([
			'languages',
			'pages',
			'structure',
		]),


		language()
		{
			return vm.$route.params.lang;
		},


		nav()
		{
			let nav = [];

			for (let i = 0; i < vm.structure.length; i++)
			{
				let item = vm.structure[i];

				if (item.alias !== 'home' && item.parent == 0 && item.hidemenu == 0)
				{
					nav.push(item);
				}
			}

			return nav;
		},
	},


	methods: {
		langSwitch(lang)
		{
			// If the language is the current language, return the current route
			if (lang == vm.language) return vm.$route;


			// Get the variables
			let vars = Object.keys(vm.page).length > 0 ? Object.keys(vm.page.variables) : [];


			// If there's a translation set, get the goto route
			if (vars.indexOf('translations') >= 0)
			{
				let tr = vm.page.variables.translations.split('||');

				for (let i = 0; i < vm.pages.length; i++)
				{
					let p = vm.pages[i];

					if (tr.indexOf(p.id) >= 0 && p.language == lang)
					{
						return vm.to(p, lang);
					}
				}
			}


			// By default, just return to the home of the prefered language
			return {
				name: 'home',
				params: {
					lang: lang,
				},
			}
		},


		lockScroll()
		{
			vm.$emit('setLocked', !vm.showNav);
		},


		to(item, lang)
		{
			let to;

			if (typeof(lang) == 'undefined') lang = vm.$route.params.lang;

			switch (parseInt(item.template))
			{
				case 2:
					to = {
						name: 'home',
						params: {
							lang: lang,
						},
					};
					break;

				case 3:
					to = {
						name: 'category',
						params: {
							alias: item.alias,
							lang: lang,
						},
					};
					break;

				case 4:
					to = {
						name: 'contact',
						params: {
							lang: lang,
						},
					};
					break;

				case 5:
					to = {
						name: 'portfolio',
						params: {
							lang: lang,
						},
					};

					break;

				case 6:
					to = {
						name: 'product',
						params: {
							alias: item.alias,
							lang: lang,
						},
					};
					break;

				default:
					to = {
						name: 'page',
						params: {
							alias: item.alias,
						},
					};
					break;
			}

			return to;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
