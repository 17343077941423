<template>
	<main v-if="pageLoaded">
		<section class="intro">
			<div>
				<h1 v-html="title" />

				<div v-html="page.content" v-if="page.content.length > 0" />
			</div>
		</section>

		<section>
			<GalleryPreview v-for="(c, id) in categories" :key="id" :page="c" :breakpoint="breakpoint" />
		</section>
	</main>
</template>

<script>
import { pageMixin } from '@/mixins/page';

import GalleryPreview from '@/components/GalleryPreview.vue';

export default {
	mixins: [
		pageMixin,
	],


	components: {
		GalleryPreview,
	},


	props: {
		breakpoint: [String, Number],
	},
}
</script>
