<template>
	<main>
		<section class="intro">
			<div>
				Redirecting...
			</div>
		</section>
	</main>
</template>

<script>
import { pageMixin } from '@/mixins/page';

import { mapGetters } from 'vuex';

export default {
	mixins: [
		pageMixin,
	],


	computed: {
		...mapGetters([
			'languages',
		]),
	},


	created()
	{
		this.$router.push({
			name: 'home',
			params: {
				lang: this.languages[0].lang,
			},
		});
	},
}
</script>
