<template>
	<form>

		<transition name="fade" mode="out-in">

			<loader v-if="processing" />

		</transition>

		<div v-if="success === false" class="feedback error">
			<p>
				Something went wrong, while trying to send your message.
			</p>

			<p>
				Please try again, or send an email directly at

				<a :href="'mailto:' + vars.email">
					{{ vars.email }}
				</a>
			</p>
		</div>

		<div v-if="success" v-html="vars.contactSuccessMessage" />

		<div v-else>
			<h2>
				Contact
			</h2>

			<ul>
				<li>
					<input type="text" v-model="contact.name" :placeholder="placeholders.name[lang]" required />
				</li>

				<li>
					<input type="email" v-model="contact.email" :placeholder="placeholders.email[lang]" required />
				</li>

				<li>
					<input type="text" v-model="contact.subject" :placeholder="placeholders.subject[lang]" required />
				</li>

				<li>
					<textarea v-model="contact.message" :placeholder="placeholders.message[lang]" required />
				</li>

				<li class="has-checkbox">
					<input type="checkbox" v-model="contact.permission" id="permission" required />

					<transition name="fade">
						<i :class="checkboxIcon" :key="checkboxIcon"></i>
					</transition>

					<label for="permission" v-if="lang === 'nl'">
						Door dit formulier te gebruiken, geef ik toestemming om de ingevulde
						informatie te gebruiken om mijn bericht te beantwoorden.
					</label>

					<label for="permission" v-else>
						By using this form, I give permission to use the information provided
						to get back in touch with me.
					</label>
				</li>

				<li>
					<a href="#" class="button dark" @click.prevent="submitForm" :disabled="!validForm" v-html="placeholders.submit[lang]" />
				</li>

				<transition name="fade">
					<li v-if="!validForm">

						<strong v-if="lang == 'nl'">
							Let op:
						</strong>
						<strong v-else>
							Note:
						</strong>

						{{ vars.contactFormNotice }}
					</li>
				</transition>
			</ul>
		</div>
	</form>
</template>

<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import Loader from '@/components/Loader.vue';

var vm;

export default {
	components: {
		Loader,
	},


	props: {
		vars: Object,
	},


	data()
	{
		return {
			contact: {
				name: '',
				email: '',
				subject: '',
				message: '',
				permission: false,
			},
			placeholders: {
				email: {
					en: 'Email...',
					nl: 'E-mail...',
				},
				message: {
					en: 'Message...',
					nl: 'Bericht...',
				},
				name: {
					en: 'Name...',
					nl: 'Naam...',
				},
				subject: {
					en: 'Subject...',
					nl: 'Onderwerp...',
				},
				submit: {
					en: 'submit',
					nl: 'verstuur',
				},
			},
			processed: false,
			submitted: false,
			success: null,
		};
	},


	computed: {
		...mapGetters([
			'base',
		]),


		checkboxIcon()
		{
			let cl = [
				'far',
				vm.contact.permission ? 'fa-check-circle' : 'fa-circle',
			];

			return cl.join(' ');
		},


		lang()
		{
			return vm.$route.params.lang;
		},


		processing()
		{
			return vm.processed !== vm.submitted;
		},


		validEmail()
		{
			return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(vm.contact.email));
		},


		validForm()
		{
			let vf = true,
				keys = Object.keys(vm.contact);

			for (let i = 0; i < keys.length; i++)
			{
				let k = keys[i],
					v = vm.contact[k];

				if ((k == 'email' && !vm.validEmail) || (k == 'permission' && !v))
				{
					vf = false;
				}
				else if (v === '')
				{
					vf = false;
				}
			}

			return vf;
		}
	},


	methods: {
		async submitForm()
		{
			vm.submitted = true;

			if (process.env.NODE_ENV == 'development')
			{
				setTimeout(() => {
					vm.success = true;
					vm.processed = true;
				}, 3000);
			}
			else
			{
				let formData = new FormData();

				formData.append('action', 'contact');
				formData.append('data', JSON.stringify(vm.contact));

				await axios.post(vm.base + 'api/forms/', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(response => {
					vm.processed = true;
					vm.success = response.data.success;
				})
				.catch(function (error)
				{
					console.log(error);
				});
			}
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
