import Vue from 'vue';
import Router from 'vue-router';

import Category from '@/views/Category.vue';
import Contact from '@/views/Contact.vue';
import DefaultPage from '@/views/DefaultPage.vue';
import Enter from '@/views/Enter.vue';
import Home from '@/views/Home.vue';
import Portfolio from '@/views/Portfolio.vue';
// import Product from '@/views/Product.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior (to, from, savedPosition) {
		if ((to.name == 'category' && from.name == 'product') || (from.name == 'category' && to.name == 'product'))
		{
			// Do nothing
		}
		else
		{
			if (savedPosition)
			{
				return savedPosition
			}
			else
			{
				return { x: 0, y: 0 }
			}
		}
    },
	routes: [
		{
			path: '/',
			name: 'landing',
			component: Enter,
		},

		{
			path: '/:lang/',
			name: 'home',
			component: Home,
		},

		{
			path: '/:lang/contact',
			name: 'contact',
			component: Contact,
		},

		{
			path: '/:lang/portfolio',
			name: 'portfolio',
			component: Portfolio,
		},
		
		{
			path: '/:lang/portfolio/:alias',
			name: 'category',
			component: Category,
			meta: {
				showModal: false,
			},
			children: [
				{
					path: ':id',
					name: 'product',
					meta: {
						showModal: true,
					},
				}
			]
		},

		// {
		// 	path: '/:lang/photo/:alias',
		// 	name: 'product',
		// 	component: Product,
		// },

		{
			path: '/:lang/:alias',
			name: 'page',
			component: DefaultPage,
		},
	]
});
