<template>
	<main v-if="pageLoaded">
		<section class="intro">
			<div>
				<h1 v-html="title" />
			</div>
		</section>

		<section>
			<div v-html="page.content" v-if="page.content.length > 0" />
		</section>
	</main>
</template>

<script>
import { pageMixin } from '@/mixins/page';

export default {
	mixins: [
		pageMixin,
	],
}
</script>
