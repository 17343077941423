<template>
	<main v-if="pageLoaded" class="contact">
		<section class="columns">
			<div>
				<h1>
					{{ title }}
				</h1>

				<div v-html="page.content" />

				<h3>
					Danny Bastiaanse Photography
				</h3>

				<ul class="icon-list">
					<li v-if="location.length > 0">
						<i class="fas fa-map-marker-alt"></i>

						<address v-html="location" />
					</li>

					<li v-if="vars.hasOwnProperty('email')">
						<i class="far fa-at"></i>

						<a :href="'mailto:' + vars.email">
							{{ vars.email }}
						</a>
					</li>

					<li v-if="vars.hasOwnProperty('phone')">
						<i class="fas fa-phone-alt"></i>

						<a :href="tel">
							{{ vars.phone }}
						</a>
					</li>

					<li v-if="vars.hasOwnProperty('instagram')">
						<i class="fab fa-instagram"></i>

						<a :href="'https://www.instagram.com/' + vars.instagram">
							@{{ vars.instagram }}
						</a>
					</li>

					<li v-if="vars.hasOwnProperty('facebook')">
						<i class="fab fa-facebook-f"></i>

						<a :href="'https://www.facebook.com/' + vars.facebook">
							@{{ vars.facebook }}
						</a>
					</li>
				</ul>
			</div>

			<div class="primary">
				<ContactForm :vars="vars" />
			</div>
		</section>
	</main>
</template>


<script>
import { pageMixin } from '@/mixins/page';

import ContactForm from '@/components/ContactForm.vue';

var vm;

export default {
	mixins: [
		pageMixin,
	],


	components: {
		ContactForm,
	},


	computed: {
		location()
		{
			let loc = [];

			if (Object.prototype.hasOwnProperty.call(vm.vars, 'address')) loc.push(vm.vars.address);
			if (Object.prototype.hasOwnProperty.call(vm.vars, 'postcodeCity')) loc.push(vm.vars.postcodeCity);

			return loc.join('<br />');
		},


		tel()
		{
			let input = vm.vars.phone;

			if (input.substr(0, 2) != '00')
			{
				let toReplace = input.substr(0, 1),
					replaceWith = toReplace === '+' ? '00' : '0031';

				input = input.replace(toReplace, replaceWith);
			}

			let chars = input.split('');
			let output = '';

			for (let i = 0; i < chars.length; i++)
			{
				let c = chars[i];

				if (!isNaN(c) && c !== ' ') output += c;
			}

			return 'tel:' + output;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
