<template>
	<router-link v-if="!cta.isExternal" :to="to" :class="classes">
		{{ cta.text }}
	</router-link>

	<a v-else :href="cta.path" :class="classes" target="_blank">
		{{ cta.text }}
	</a>
</template>

<script>
var vm;

export default {
	props: {
		cta: Object,
	},


	created()
	{
		vm = this;
	},


	computed: {
		classes()
		{
			let cl = ['button'];

			cl.push(vm.cta.color);

			return cl.join(' ');
		},


		to()
		{
			return {
				path: vm.cta.path,
			};
		},
	},
}
</script>
