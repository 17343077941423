<template>
	<footer>
		<span>
			<i class="far fa-copyright"></i>
			Copyright
			{{ year }}
		</span>

		<span v-for="link in footerLinks" :key="['fl', link.id].join('-')">
			<router-link :to="{ name: 'page', params: { alias: link.alias } }">
				{{ link.pagetitle }}
			</router-link>
		</span>

		<span>
			<i class="far fa-desktop-alt"></i>

			{{ realisation }}

			<a href="https://dizero.nl" target="_blank" class="dizero">
				Dizero
			</a>
		</span>
	</footer>
</template>

<script>
import { mapGetters } from 'vuex';

var vm;

export default {
	data()
	{
		return {
			year: new Date().getFullYear(),
		};
	},


	computed: {
		...mapGetters([
			'pages',
		]),


		footerLinks()
		{
			let fl = [];

			for (let i = 0; i < vm.pages.length; i++)
			{
				let p = vm.pages[i],
					vars = Object.keys(p.variables);

				if (vars.indexOf('isFooterLink') && p.variables.isFooterLink == 1 && p.language == vm.$route.params.lang)
				{
					// console.log(p.language + ' / ' + vm.);
					fl.push(p);
				}
			}

			return fl;
		},


		realisation()
		{
			let text = 'Realisation';

			switch (vm.$route.params.lang)
			{
				case 'nl':
					text = 'Realisatie';
					break;
			}

			text += ':';

			return text;
		},
	},


	created()
	{
		vm = this;
	},
}
</script>
