<template>
	<router-link :to="to" v-if="!item.external">
		<slot></slot>
	</router-link>

	<a :href="item.href" v-else target="_blank" @click.prevent="toShopCart">
		<slot></slot>
	</a>
</template>

<script>
import { Base64 } from 'js-base64';

var vm;

export default {
	props: {
		item: Object,
		to: Object,
	},


	methods: {
		toShopCart(e)
		{
			let el = e.target;

			while (el.tagName.toLowerCase() != 'a')
			{
				el = el.parentNode;
			}

			let href = el.href;

			// If the link goes to the shop, open it in the same tab
			if (href.indexOf('.') < 0 || href.indexOf('dannybastiaanse.com') >= 0)
			{
				let url = 'https://shop.dannybastiaanse.com/enter/?l=' + vm.$route.params.lang + '&c=';
				// url = 'https://dev.dizero.nl/shop.dannybastiaanse.com/enter/?l=' + vm.$route.params.lang + '&c=';
				
				url += Base64.encode(vm.$session.get('cart'));

				// If a return URL is given, add that parameter
				if (href.substr(-5) != '/cart')
				{
					url += '&u=' + Base64.encode(href);
				}
				
				/**
				*	With the URL determined, we can clear the cart in the website, so that
				*	when the user returns, perhaps after having paid, that same cart isn't
				*	still in the session.
				*/
				vm.$session.remove('cart');
				
				// Redirect to the URL
				window.location.href = url;
			}
			// Otherwise, just open a new tab
			else
			{
				window.open(href);
			}
		},
	},



	created()
	{
		vm = this;
	},
}
</script>
